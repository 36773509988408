import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import PostCard from '../components/postCard';
import Layout from '../components/layout';
import TwoColCardSection from '../components/twoColCardSection';

export default ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  const posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostCard key={edge.node.id} post={edge.node} />);

  return (
    <Layout>
      <Helmet>
        <title>Blog Posts</title>
      </Helmet>
      <h1>Blog Posts</h1>
      <TwoColCardSection>{posts}</TwoColCardSection>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
